import Vue from "vue"
import ElementUI from "element-ui"
import "../../plugins/element-ui/theme/element-B20FD3/index.css"
import "../../assets/css/common.scss"
import App from "./app.vue"

// svg图标
// import "@/icons" // icon

Vue.use(ElementUI)

Vue.config.productionTip = false
new Vue({
  render: (h) => h(App),
}).$mount("#app")
