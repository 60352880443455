/* * @Author: chenliyan * @Date: 2022-08-23 15:50:20 * @Description:
主播PC端首页 */
<template>
  <div class="index-wrap">
    <div class="home-top">
      <div class="logo">真快乐直播</div>
      <div class="btns">
        <template v-if="!isLogined">
          <el-dropdown class="btns-type" @command="loginHandle">
            <el-button size="small">立即登录</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">企业</el-dropdown-item>
              <el-dropdown-item command="2">个人</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            size="small"
            type="primary"
            @click="registerHandle"
            style="margin-left: 20px"
            >免费注册</el-button
          >
        </template>
        <template v-if="isLogined">
          <span class="nick-name">{{
            userInfo.nickName || userInfo.nikeName
          }}</span>
          <el-button size="small" @click="goToCenter">管理中心</el-button>
          <el-button size="small" @click="onLogout">退出登录</el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchGetIAMUserinfo,
  fetchGetGomeUserinfo,
  fetchIAMLogout,
  fetchGomeLogout,
} from "../../api/auth"
import { env } from "config/env"
import { setCookie } from "@/utils/cookie"

let domain = ".gome.com.cn"
let type = /\.(\w+)\.com\.cn$/i.test(location.host) && RegExp.$1
if (type == "gomeuat") {
  domain = ".gomeuat.com.cn"
}

export default {
  name: "ZBIndex",
  components: {},
  data() {
    return {
      // 是否已登录了统一认证平台
      isLoginedIAM: false,
      // 是否已登录了真快乐
      isLogindGome: false,
      // 统一认证登录用户信息
      IAMUserInfo: null,
      // 国美登录用户信息
      gomeUserInfo: null,
    }
  },
  computed: {
    // 是否已登录
    isLogined() {
      return !!(this.isLoginedIAM || this.isLogindGome)
    },
    // 当前登录用户信息：优先取统一认证平台的
    userInfo() {
      return this.IAMUserInfo || this.gomeUserInfo || {}
    },
  },
  created() {
    this.getPPUserinfo()
    this.getGomeUserInfo()
  },
  mounted() {},
  methods: {
    // 获取统一认证平台用户信息
    getPPUserinfo() {
      fetchGetIAMUserinfo().then((res) => {
        if (res.code == 0 && res.data) {
          setCookie("zhubo_platform", "company", 1, domain)
          this.isLoginedIAM = true
          this.IAMUserInfo = res.data.accountInfo
        }
      })
    },
    // 获取真快乐登录账户信息
    getGomeUserInfo() {
      fetchGetGomeUserinfo().then((res) => {
        if (res.isSuccess == "Y") {
          this.isLogindGome = true
          this.gomeUserInfo = res
          if (!this.isLoginedIAM) {
            setCookie("zhubo_platform", "personal", 1, domain)
          }
        }
      })
    },
    // 去企业或个人中心
    goToCenter() {
      if (this.isLoginedIAM) {
        // 企业中心
        window.location.href = env.zbCenterUrl
      } else if (this.isLogindGome) {
        // 个人中心
        window.location.href = env.myCenterUrl
      }
    },
    // 登录
    loginHandle(command) {
      if (command == 1) {
        setCookie("zhubo_platform", "company", 1, domain)
        // 企业登录
        window.location.href = env.loginPPUrl
      } else if (command == 2) {
        setCookie("zhubo_platform", "personal", 1, domain)
        // 个人登录
        window.location.href = env.loginIUrl
      }
    },
    // 去注册
    registerHandle() {
      window.open(env.registerUrl)
    },
    // 退出登录
    onLogout() {
      if (this.isLoginedIAM) {
        fetchIAMLogout().then((res) => {
          if (res.code == 0) {
            this.isLoginedIAM = false
            this.IAMUserInfo = null
            // 同时需退出国美账号
            fetchGomeLogout().then((res) => {
              if (res.result || res.isSuccess == "Y") {
                this.$message.success("退出成功")
                this.isLogindGome = false
                this.gomeUserInfo = null
              }
            })
          }
        })
      } else if (this.isLogindGome) {
        // 国美账号退出登录
        fetchGomeLogout().then((res) => {
          if (res.result || res.isSuccess == "Y") {
            this.$message.success("退出成功")
            this.isLogindGome = false
            this.gomeUserInfo = null
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// 顶部
.home-top {
  width: 100%;
  height: 68px;
  overflow-x: hidden;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    font-size: 14px;
    color: #303133;
    margin-left: 24px;
    background: url("../../assets/images/index/logo.png?t=20211012") no-repeat;
    background-size: auto 50px;
    background-position: left center;
    padding-left: 92px;
    height: 68px;
    width: 350px;
    line-height: 68px;
    font-weight: 600;
  }

  .btns {
    margin-right: 24px;
  }

  .btns-type {
    margin-left: 24px;
  }

  .nick-name {
    margin-right: 12px;
    font-size: 14px;
    color: #303133;
  }
}
</style>
<style lang="scss">
body {
  min-width: 1100px;
}
.banner-box {
  position: relative;
  height: 500px;
  min-height: calc(100vh - 68px);
  padding-top: 340px;
  box-sizing: border-box;
  background: url("../../assets/images/index/banner.png") no-repeat center top;
  background-size: cover;
  box-sizing: border-box;
  .banner-cont {
    width: 1100px;
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    padding: 5px 22px;
    color: #fff;
    box-sizing: border-box;
    h1 {
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
    }
    .text {
      margin-top: 16px;
      font-size: 25px;
      line-height: 35px;
    }
  }
}
</style>
